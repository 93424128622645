import './App.css';
import React from 'react'
import Routes from '../src/router/router'

function App() {
  return (
    <div>
      <Routes/>
    </div>
  );
}

export default App;
